<template>
  <div>
    <v-alert
      v-model="alert"
      close-label="Kapat"
      color="accent"
      dark
      absolute
      class="survey"
      rounded="0"
      elevation="24"
    >
      <v-container>
        <v-row no-gutters align="center">
          <v-col cols="12" sm="10">
            Would you like to participate in the User Experience Survey we prepared to improve our service quality?
          </v-col>
          <v-col cols="12" sm="2" class="text-right">
            <v-btn color="primary" @click="openDialog()" small elevation="0">
              OPEN SURVEY
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-alert>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar elevation="0">
          <v-spacer />
          <v-btn text @click="closeDialog()">
            CLOSE
            <v-icon class="ml-2">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="viewer">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfUfO9lyi87x_JvaOqGgetrBg62sTj2jilzvBdKbhE4OHEQgQ/viewform?embedded=true"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alert: true,
      dialog: false
    };
  },

  methods: {
    openDialog() {
      this.alert = false;
      this.dialog = true;
    },

    closeDialog() {
      this.alert = true;
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.survey {
  position: fixed !important;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0 !important;
  padding: 0 !important;
}

.viewer {
  height: 100%;

  iframe {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100% - 64px);
    border: none 0 transparent;
  }
}
</style>
